var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto px-4 py-4",attrs:{"tile":""}},[_c('v-card-title',{staticClass:"no-overflow-x pl-0 pr-0"},[_c('span',[_vm._v("I love to network with new people, so feel free to reach out!")])]),_c('v-card-text',{staticClass:"no-overflow-x pl-0 pr-0"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":20,"error-messages":errors,"label":"Full Name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"error-messages":errors,"label":"Subject","data-vv-name":"select","required":""},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Message","required":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)}),_c('v-card-actions',{staticClass:"d-block"},[_c('div',{staticClass:"mb-4"},[_c('validation-provider',{attrs:{"name":"recaptcha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('recaptcha',{attrs:{"clear":_vm.clearRecaptcha,"sitekey":_vm.sitekey,"error-messages":errors},on:{"onVerified":_vm.onRecaptchaVerified},model:{value:(_vm.recaptcha),callback:function ($$v) {_vm.recaptcha=$$v},expression:"recaptcha"}})]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('v-btn',{staticClass:"primary mr-4",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" submit ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" clear ")])],1),_c('div',[_c('p',{attrs:{"id":"my-form-status"},domProps:{"innerHTML":_vm._s(_vm.statusMessage)}})])])],1)]}}])})],1),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }